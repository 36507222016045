import React, { useState } from "react";
import { useIdentity } from "../../identity/IdentityProvider";
import { Link } from "react-router-dom";
import BaseSinglePage from "../BaseSinglePage";
import { Alert } from "reactstrap";

const Login = () => {
  const { login } = useIdentity();
  const [data, setData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    login(data).then(res => {
      setErrors(res.errors);
    });
  }

  const setEmail = (email) => { setData({ ...data, email: email }); }
  const setPass = (password) => { setData({ ...data, password: password }); }

  return (
    <BaseSinglePage>
      <div className="p-lg-5 p-4">
        <div>
          <h5>Witamy!</h5>
          <p className="text-muted">Logowanie do systemu.</p>
        </div>
        <div className="mt-4 pt-3">
          {errors.map(error => (<Alert key={error} color="danger">{error}</Alert>))}
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="email" className="mb-2">Login</label>
              <input name="email" label="Login" className="form-control" placeholder="Wprowadź login" type="email" required value={data.email} onInput={e => setEmail(e.target.value)} />
            </div>

            <div className="mb-3">
              <label htmlFor="password" className="mb-2">Hasło</label>
              <input name="password" label="Hasło" className="form-control" placeholder="Wprowadź hasło" type="password" required value={data.password} onInput={e => setPass(e.target.value)} />
            </div>

            <div className="form-check">
              <input type="checkbox" className="form-check-input" />
              <label className="form-check-label" >Zapamiętaj mnie</label>
              <div className="float-end">
                <Link to="/forget-password" className="text-muted">Przypomnij hasło</Link>
              </div>
            </div>

            <div className="mt-3">
              <button className="btn btn-primary w-100" type="submit">Zaloguj</button>
            </div>
          </form>
        </div>
      </div>
    </BaseSinglePage>
  )
}

export default Login