import { Label } from "reactstrap";

const AmountInput = (props) => {
    return (
        <>
            <Label>Kwota</Label>
            <div className="input-group">
                <input className="form-control" type="text" value={props.value} onChange={(e) => props.setValue(e.target.value)} />
                <div className="input-group-prepend">
                    <span className="input-group-text">PLN</span>
                </div>
            </div>
        </>
    );
}

export default AmountInput;