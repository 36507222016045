import { useDispatch, useSelector } from 'react-redux';
import { getFilters, updateFilters } from "../../../redux/budgetRedux.js";
import { ButtonGroup, Button } from "reactstrap";

const KindsFilter = () => {

    const dispatch = useDispatch();

    const filters = useSelector(state => getFilters(state));

    const setKind = (kind) => { dispatch(updateFilters({ ...filters, kind: kind })); };

    return (
        <ButtonGroup className="d-flex mb-3 kind-btn">
            <Button color="primary" outline onClick={() => setKind("all")} active={filters.kind === "all"}>Wszystko</Button>
            <Button color="primary" outline onClick={() => setKind("expense")} active={filters.kind === "expense"}>Wydatki</Button>
            <Button color="primary" outline onClick={() => setKind("profit")} active={filters.kind === "profit"}>Wpływy</Button>
        </ButtonGroup>
    );
}

export default KindsFilter;