import Select, { components } from "react-select";
import { Label } from "reactstrap";
import endpoints from "../../../../config/endpoints.js";
import { get } from "../../../../api/authApiClient.js";
import { useEffect, useState } from "react";

const PersonInput = (props) => {

    const fetchPersons = async () => {
        const data = await get(endpoints.operations.persons);
        if (data.isOk()) {
            setPersons(data.response.map(person => { return { value: person.id, label: person.name } }));
        }
    };

    useEffect(() => { fetchPersons(); }, []);
    const [persons, setPersons] = useState([]);

    const personHtml = (person) => {
        return (<><small className={person.slice(-1) === "a" ? "fa fa-female me-1" : "fa fa-male"} ></small> {person}</>);
    }

    const PersonOption = props => (
        <components.Option {...props}>
            {personHtml(props.data.label, props.data.codename)}
        </components.Option>
    );

    const MultiPersonSelected = props => {
        return (
            <components.MultiValue {...props}>
                {personHtml(props.data.label)}
            </components.MultiValue>
        );
    };

    const handleChange = value => {
        const ids = value.map(a => a.value);
        props.setValue(ids);
    }

    return (
        <>
            <Label>Kto</Label>
            <div className="row pe-0">
                <div className="col-11 pe-0">
                    <Select
                        value={persons.filter(person => props.value.includes(person.value))} 
                        onChange={handleChange}
                        defaultValue={[...persons]}
                        isMulti={true}
                        components={{ Option: PersonOption, MultiValue: MultiPersonSelected }}
                        options={persons}
                        placeholder="Wybierz..."
                        classNamePrefix="select2-selection"
                        closeMenuOnSelect={false}
                    />
                </div>
                <button className="btn btn-outline-secondary col-1" type="button" onClick={() => {props.setValue(persons.map(person => person.value))}}><i className="fa fa-list-alt"></i></button>
            </div>
        </>
    );
}

export default PersonInput;