import { createStore, combineReducers } from "redux";
import budgetReducer from "./budgetRedux";
import monthDefinitionReducer from "./monthDefinitionRedux";

const subreducers = {
  budget: budgetReducer,
  monthDefinition: monthDefinitionReducer
}

const reducer = combineReducers(subreducers);
const store = createStore(
  reducer,
  {
    budget: {
      filters: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        search: undefined,
        kind: "all",
        from: undefined,
        to: undefined,
        categoryIds: undefined
      }
    },
    monthDefinition: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      show: false,
      items: [],
      types: []
    }
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;