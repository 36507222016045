import { useState } from "react";

import OperationDetails from "./OperationDetails";
import getCategoryIcon from "./CategoryHelper.js";
import getPaymentIconHtml from "./PaymentHelper";

const Operation = (props) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const amountFormatter = (amount) => {
        const type = amount < 0 ? "danger" : "success";
        return <h5 className="my-auto"><span className={"badge bg-" + type + " bg-soft text-" + type}>{amount.toLocaleString("fr-FR")} PLN</span></h5>
    };

    const categoryFormatter = (category) => {
        const icon = getCategoryIcon(category.codename);
        return <span className={"me-2 text-" + icon.color}><i className={"fa fa-" + icon.icon}></i> {category.name}</span>
    };

    return (<>
        <div className={isOpen ? "row py-2 bg-light" : "row border-bottom py-2"}>
            <div className="col-1 my-auto">{getPaymentIconHtml(props.operation.payment)}</div>
            {
                isOpen ?
                    <>
                        <div className="col-7 my-auto">
                            SZCZEGÓŁY OPERACJI
                        </div>
                        <div className="col-3 my-auto text-end fs-2 fw-bold">{amountFormatter(props.operation.value)}</div>
                    </>
                    :
                    <>
                        <div className="col-5 my-auto">
                            {props.operation.company !== null && props.operation.company !== undefined
                                ? <div className="my-auto">
                                    <div>{props.operation.company}</div>
                                    <div className="text-truncate text-muted fw-6">{props.operation.description}</div>
                                </div>
                                : <>
                                    <div className="text-truncate">{props.operation.description}</div>
                                </>
                            }
                        </div>
                        <div className="col-3 my-auto">{categoryFormatter(props.operation.category)}</div>
                        <div className="col-2 my-auto text-end">{amountFormatter(props.operation.value)}</div>
                    </>
            }
            <div className="col-1 my-auto">
                <span onClick={toggle} className="btn nohover">
                    <small>
                        {isOpen ?
                            <i className="fa fa-chevron-up text-muted"></i>
                            :
                            <i className="fa fa-chevron-down text-muted"></i>
                        }
                    </small>
                </span>
            </div>
        </div>
        {isOpen ? <OperationDetails id={props.operation.id} showOperation={props.showOperation} refreshOperations={props.refreshOperations}/> : <></>}
    </>
    );
}

export default Operation