import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./styles/global.scss";
import "font-awesome/css/font-awesome.min.css";
import { BrowserRouter } from "react-router-dom";
import { IdentityProvider } from "./components/identity/IdentityProvider.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <IdentityProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </IdentityProvider>
  </BrowserRouter>
  // </React.StrictMode>
);