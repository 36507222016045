import { Container } from "react-bootstrap";
import "./Footer.module.scss";

const Footer = () => {
    return (
        <footer className="mt-3 fixed-bottom">
            <Container className="text-center p-3">
              <span className="text-muted">DOM 4.0 © 2022 KJ</span>  
            </Container>
        </footer>
    );
}

export default Footer;