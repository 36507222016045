import shortid from "shortid";

// selectors
export const getIsShow = ({ monthDefinition }) => monthDefinition.show;
export const getItems = ({ monthDefinition }) => monthDefinition.items;
export const getTypes = ({ monthDefinition }) => monthDefinition.types;
export const getYear = ({ monthDefinition }) => monthDefinition.year;
export const getMonth = ({ monthDefinition }) => monthDefinition.month;


// actions
const createActionName = actionName => `app/budget/monthDefinition/${actionName}`;
const SHOW = createActionName("SHOW");
const HIDE = createActionName("HIDE");
const ADD = createActionName("ADD");
const REMOVE = createActionName("REMOVE");
const UPDATE = createActionName("UPDATE");
const SET_ITEMS = createActionName("SET_ITEMS");
const SET_TYPES = createActionName("SET_TYPES");
const SET_YEAR = createActionName("SET_YEAR");
const SET_MONTH = createActionName("SET_MONTH");

// action creators
export const showMonthDefinition = () => ({ type: SHOW });
export const hideMonthDefinition = () => ({ type: HIDE });
export const add = () => ({ type: ADD });
export const remove = payload => ({ type: REMOVE, payload });
export const update = payload => ({ type: UPDATE, payload });
export const setItems = payload => ({ type: SET_ITEMS, payload });
export const setTypes = payload => ({ type: SET_TYPES, payload });
export const setYear = payload => ({ type: SET_YEAR, payload });
export const setMonth = payload => ({ type: SET_MONTH, payload });

const monthDefinitionReducer = (statePart = {}, action) => {
  switch (action.type) {
    case SHOW:
      return { ...statePart, show: true };
    case HIDE:
      return { ...statePart, show: false };
    case ADD:
      return { ...statePart, items: [...statePart.items, { id: shortid(), value: 0, monthDefinitionTypeId: 0 }] };
    case REMOVE:
      return { ...statePart, items: [...statePart.items.filter(item => item.id !== action.payload)] };
    case UPDATE:
      return { ...statePart, items: statePart.items.map(item => (item.id === action.payload.id) ? { ...action.payload } : item) };
    case SET_ITEMS:
      return { ...statePart, items: action.payload.map(item => ({ ...item, id: shortid() })) };
    case SET_TYPES:
      return { ...statePart, types: action.payload };
    case SET_YEAR:
      return { ...statePart, year: action.payload };
    case SET_MONTH:
      return { ...statePart, month: action.payload };
    default:
      return statePart;
  };
};

export default monthDefinitionReducer;