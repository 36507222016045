import { config } from "./config.js";

const endpoints = () => ({
    identity: {
        login: "/Users/Identity/SignIn",
        forgetPassword: "/Users/Identity/RemindPassword",
        setPassword: "/Users/Identity/SetPassword"
    },
    operations: {
        all: "/Budget/Operations",
        single: (id) => `/Budget/Operations/${id}`,
        create: "/Budget/Operations",
        update: (id) => `/Budget/Operations/${id}`,
        delete: (id) => `/Budget/Operations/${id}`,
        details: (id) => `/Budget/Operations/${id}/Details`,
        allCategories: "/Budget/Operations/Categories",
        expenseCategories: "/Budget/Operations/Categories/Expenses",
        profitCategories: "/Budget/Operations/Categories/Profits",
        persons: "/Budget/Operations/Persons",
        companies: "/Budget/Operations/Companies",
        payments: "/Budget/Operations/Payments"
    },
    monthDefinitions: {
        all: (year, month) => `/Budget/MonthDefinitions/${year}/${month}`,
        update: (year, month) => `/Budget/MonthDefinitions/${year}/${month}`,
        types: "/Budget/MonthDefinitions/Types"
    }
});

const prepareEndpoints = () => {
    const obj = { ...endpoints() }
    const prepare = (object) => {
        Object.entries(object).forEach(([key]) => {
            if (typeof object[key] === "object")
                prepare(object[key])
            else if (typeof object[key] === "function") {
                const func = object[key];
                object[key] = (...params) => config.API_URL + func(...params)
            }
            else
                object[key] = config.API_URL + object[key]
        });
    }
    prepare(obj);
    return obj;
}

export default prepareEndpoints();