import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/custom.scss"
import { Route, Routes } from "react-router-dom";

import { useIdentity } from "./components/identity/IdentityProvider.js";
import { AuthRedirect } from "./components/identity/AuthRedirect.js";

import Layout from "./components/views/Layout/Layout.js";
import publicRoutes from "./components/routes/publicRoutes.js";
import protectedRoutes from "./components/routes/protectedRoutes.js";

function App() {
  const { user } = useIdentity();

  return (
    <Routes>
      {publicRoutes.map((route, i) => (
        <Route key={i} path={route.path} element={
          (route.withoutAuth ?
            <AuthRedirect isAuth={user} path="/">
              <route.component />
            </AuthRedirect>
            :
            <route.component />
          )
        }
        />
      ))}

      {protectedRoutes.map((route, i) => (
        <Route key={i} path={route.path} element={
          <AuthRedirect isAuth={!user} path="/login">
            <Layout page={<route.component />} />
          </AuthRedirect>}
        />
      ))}
    </Routes>
  );
}

export default App;
