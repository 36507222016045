import Select, { components } from "react-select";
import { Label } from "reactstrap";
import endpoints from "../../../../config/endpoints.js";
import { get } from "../../../../api/authApiClient.js";
import { useEffect, useState } from "react";
import getPaymentIconHtml from "../PaymentHelper";

const PaymentInput = (props) => {

    const fetchPayments = async () => {
        const data = await get(endpoints.operations.payments);
        if (data.isOk()) {
            setPayments(data.response.map(payment => { return { value: payment.id, label: payment.name, codename: payment.codename } }));
        }
    };

    useEffect(() => { fetchPayments(); }, []);
    const [payments, setPayments] = useState([]);

    const PaymentOption = props => (
        <components.Option {...props}>
            {getPaymentIconHtml(props.data.codename)} {props.data.label}
        </components.Option>
    );

    const PaymentSelected = props => {
        return (
            <components.SingleValue {...props}>
                {getPaymentIconHtml(props.data.codename)} {props.data.label}
            </components.SingleValue>
        );
    };

    return (
        <>
            <Label className="form-label">Płatność</Label>
            <Select
                value={payments.filter(payment => payment.value == props.value)[0]}
                onChange={(value) => props.setValue(value.value)}
                //defaultValue={payments[0]}
                components={{ Option: PaymentOption, SingleValue: PaymentSelected }}
                options={payments}
                placeholder="Wybierz..."
                classNamePrefix="select2-selection"
                closeMenuOnSelect={true}
            />
        </>
    );
}

export default PaymentInput;