import React, { useState } from "react"
import "./SearchMenu.module.scss";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import SimpleBar from "simplebar-react"

const SearchMenu = () => {
    const [search, setSearch] = useState(false);

    return (
        <>
            <Dropdown isOpen={search} toggle={() => setSearch(!search)} className="d-inline-block">
                <DropdownToggle className="btn header-icon-none-focus" tag="button" >
                    <i className="fa fa-search header-icon-menu d-none d-xl-inline-block text-muted" />
                </DropdownToggle>

                <DropdownMenu className="p-0">
                    <SimpleBar style={{ height: "230px" }}>
                    </SimpleBar>
                </DropdownMenu>
            </Dropdown>
        </>
    );
}

export default SearchMenu;