import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../storage/useLocalStorage";
import endpoints from "../../config/endpoints.js";
import { APP_SESSION_NAME } from "../../config/config.js";
const IdentityContext = createContext();

export const IdentityProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage(APP_SESSION_NAME, null);
    const navigate = useNavigate();
    
    const login = async (data) => {
        let res = await fetch(endpoints.identity.login, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        let json = await res.json();
        if (res.ok) {
            setUser(json);
            navigate("/");
            return {};
        }
        else if (res.status === 400) {
            return json;
        }
        else {
            navigate("/500");
            return {};
        }
    };

    const logout = () => {
        setUser(null);
        navigate("/login", { replace: true });
    };

    const forgetPassword = async (data) => {
        let res = await fetch(endpoints.identity.forgetPassword, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        if (res.ok) {
            return true;
        }
        else {
            navigate("/500");
            return {};
        }
    };

    const setPassword = async (data) => {
        let res = await fetch(endpoints.identity.setPassword, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        if (res.ok) {
            return true;
        }
        else if (res.status === 400) {
            return await res.json();
        }
        else {
            navigate("/500");
            return {};
        }
    };

    const value = useMemo(
        () => ({
            user,
            login,
            logout,
            forgetPassword,
            setPassword
        }),
        [user]
    );
    return <IdentityContext.Provider value={value}>{children}</IdentityContext.Provider>;
};

export const useIdentity = () => {
    return useContext(IdentityContext);
};