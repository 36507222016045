import "./Style.scss";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import YearsFilter from "./YearsFilter";
import MonthsFilter from "./MonthsFilter";
import { useDispatch, useSelector } from 'react-redux';
import { getFilters } from "../../../redux/budgetRedux.js";
import KindsFilter from "./KindsFilter";
import AmountsFilter from "./AmountsFilter";
import CategoriesFilter from "./CategoriesFilter";
import SearchFilter from "./SearchFilter";
import { showMonthDefinition } from "../../../redux/monthDefinitionRedux";

const FilterWidget = (props) => {

    const dispatch = useDispatch();

    const filters = useSelector(state => getFilters(state));

    return (
        <>
            <div className="filter-widget">
                <YearsFilter />
                <MonthsFilter />
                <h5 className="mb-3">Flitry</h5>
                <SearchFilter />
                <KindsFilter />
                <h6 className="mb-1">Kwota</h6>
                <AmountsFilter />
                <h6 className="mb-1">Rodzaj</h6>
                <CategoriesFilter />
                <div className="mb-3 d-flex justify-content-between">
                    <button type="button" className="btn btn-outline-primary" onClick={() => props.onFilter(filters)}>Filtruj</button>
                    <div className="d-flex">
                        <button type="button" className="btn btn-outline-warning me-1" onClick={() => props.showOperation("Expense")}><i className="fa fa-shopping-cart"></i></button>
                        <UncontrolledDropdown>
                            <DropdownToggle color="outline-primary" caret>
                                Akcje
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => props.showOperation("Expense")}>Dodaj wydatek</DropdownItem>
                                <DropdownItem onClick={() => props.showOperation("Profit")}>Dodaj środki</DropdownItem>
                                <DropdownItem onClick={() => { dispatch(showMonthDefinition()) }}>Definiuj miesiąc</DropdownItem>
                                <div className="dropdown-divider"></div>
                                <DropdownItem onClick={() => props.importOperationHandle()}>Impotuj operacje</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FilterWidget;