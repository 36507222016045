import React from "react";
import { Container } from "reactstrap";

import Breadcrumbs from "../../common/Breadcrumb/Breadcrumb.js";

const Home = () => {
    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title={"Strona główna"} breadcrumbItem={"Strona główna"} />
            </Container>
        </div>
    );
}

export default Home