import { Response } from "./response.js";
import { APP_SESSION_NAME } from "../config/config.js";

const getRequestOptions = (method, token) => {
    const headers = {
        "Content-Type": "application/json; charset=utf-8"
    };

    return {
        method: method,
        cache: "no-cache",
        headers: token
            ? { ...headers, ...{ Authorization: "Bearer " + token } }
            : headers
    }
}

const doFetch = (url, options) => {
    return fetch(url, options).then(response => {
        if (response.status === 401) {
            window.localStorage.setItem(APP_SESSION_NAME, null);
            window.location = "/login";
        }
        return response.json()
            .then(result => new Response(response.status, result))
            .catch(() => new Response(response.status));
    });
}

export const get = (url, token) => {
    const options = getRequestOptions("GET", token);

    return doFetch(url, options);
};

export const post = (url, data, token) => {
    const options = {
        ...getRequestOptions("POST", token),
        body: JSON.stringify(data)
    };

    return doFetch(url, options);
};

export const put = (url, data, token) => {
    const options = {
        ...getRequestOptions("PUT", token),
        body: JSON.stringify(data)
    };

    return doFetch(url, options);
};

export const del = (url, token) => {
    const options = getRequestOptions("DELETE", token);

    return doFetch(url, options);
};