import React from "react"
import { Row, Col, Container, Card } from "reactstrap"
import { Link } from "react-router-dom"

import logo from "../../assets/images/logo-dark.png"

const BaseSinglePage = (props) => {

  return (
    <>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={5}>
              <div className="text-center mb-5">
                <Link to="/" className="auth-logo">
                  <img src={logo} alt="" height="28" className="auth-logo-dark" />
                </Link>
                <p className="font-size-15 text-muted mt-3">Aplikacja <b>DOM</b> w czwartej odsłonie</p>
              </div>
              <Card className="overflow-hidden">
                <Row className="g-0">
                  <Col lg={12}>
                    {props.children}
                  </Col>
                </Row>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} K. J.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}


export default BaseSinglePage