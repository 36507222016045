import { Label } from "reactstrap";

const DateInput = (props) => {
    const formatDate = (date) => `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;

    const handleChange = e => {
        e.preventDefault();
        const d = new Date(e.target.value);
        props.setValue(d);
    }

    return (
        <>
            <Label>Data</Label>
            <input
                value={formatDate(props.value)}
                onChange={handleChange}
                // defaultValue={formatDate(date)} 
                className="form-control"
                type="date"
            />
        </>
    );
}

export default DateInput;