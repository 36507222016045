import { useDispatch, useSelector } from 'react-redux';
import { getFilters, updateFilters } from "../../../redux/budgetRedux.js";

const SearchFilter = () => {

    const dispatch = useDispatch();

    const filters = useSelector(state => getFilters(state));

    return (
        <div className="mb-3">
            <input type="text" className="form-control" placeholder="Szukaj..." value={filters.search} onChange={e => {
                e.preventDefault();
                dispatch(updateFilters({ ...filters, search: e.target.value }))
            }}/>
        </div>
    );
}

export default SearchFilter;