export class Response {

    constructor(status, response) {
        this.status = status;
        this.response = response;
    }

    isOk = () => this.status === 200 || this.status === 201 || this.status === 204;

    isError = () => this.status === 400;

    isException = () => !this.isOk() && !this.isError();
}