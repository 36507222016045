import Login from "../pages/Login/Login.js";
import ForgetPassword from "../pages/ForgetPassword/ForgetPassword.js";
import SetPassword from "../pages/SetPassword/SetPassword.js";
import NotFound from "../pages/NotFound.js";
import InternalServerError from "../pages/InternalServerError.js";

const publicRoutes = [
    { path: "/login", component: Login, withoutAuth: true },
    { path: "/forget-password", component: ForgetPassword, withoutAuth: true },
    { path: "/set-password", component: SetPassword, withoutAuth: true },
    { path: "/404", component: NotFound },
    { path: "/500", component: InternalServerError }
]

export default publicRoutes;