import React from "react";
import { Container } from "reactstrap";
import Header from "../Header/Header.js";
import Navbar from "../Navbar/Navbar.js";
import Footer from "../Footer/Footer.js";

const Layout = (props) => {
    return (
        <>
            <Header />
            <Navbar />
            <Container style={{ marginBottom: "70px" }}>
                {props.page}
            </Container>
            <Footer />
        </>
    );
}

export default Layout;