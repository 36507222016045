import React, { useState } from "react"
import styles from "./NotificationMenu.module.scss";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import SimpleBar from "simplebar-react"

const NotificationMenu = () => {
    const [profile, setProfile] = useState(false);

    return (
        <>
            <Dropdown isOpen={profile} toggle={() => setProfile(!profile)} className="d-inline-block">
                <DropdownToggle className="btn header-icon-none-focus" tag="button" >
                    <i className="fa fa-bell header-icon-menu d-none d-xl-inline-block text-muted" />
                </DropdownToggle>

                <DropdownMenu className={styles.notificationMenu + " p-0"}>
                    <div className="p-3 d-flex justify-content-between">
                        <div>
                            <h6 className="p-1">Powiadomienia</h6>
                        </div>
                        <div>
                            <a href="/" className="btn btn-sm link-primary">Zobacz wszystkie</a>
                        </div>
                    </div>

                    <SimpleBar style={{ height: "230px" }}>
                    </SimpleBar>

                    <div className="p-2 border-top d-grid">
                        <a className="btn btn-sm link-primary font-size-14 btn-block text-center" href="/">
                            <i className="fa fa-chevron-circle-right me-1"></i>Zobacz wszystkie
                        </a>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </>
    );
}

export default NotificationMenu;