
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Draggable from 'react-draggable';

const ModalForm = (props) => {

    const toggle = () => props.toggle();   

    return (
        <Draggable handle=".modal-header">
            <Modal isOpen={props.isOpen} toggle={toggle} >
                <ModalHeader toggle={toggle}>{props.title}</ModalHeader>
                <ModalBody>
                    {props.children}
                </ModalBody>
                <ModalFooter>
                    {typeof props.footer === 'undefined' ?
                        <>
                            <Button color="secondary" onClick={toggle}>Anuluj</Button>
                            <Button color="primary"onClick={props.onAccept}>Zapisz</Button>
                        </>
                        :
                        props.footer
                    }
                </ModalFooter>
            </Modal>
        </Draggable>
    );
}

export default ModalForm;