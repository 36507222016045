import { useEffect, useState } from "react";

import endpoints from "../../../config/endpoints.js";
import { get, del } from "../../../api/authApiClient.js";
import getCategoryIcon from "./CategoryHelper.js";

const OperationDetails = (props) => {

    const fetchOperationDetails = async () => {
        const url = endpoints.operations.details(props.id);

        const data = await get(url);
        if (data.isOk()) {
            setOperation(data.response);
        }
    };

    const removeOperation = async () => {
        const url = endpoints.operations.delete(props.id);

        const data = await del(url);
        if (data.isOk()) {
            props.refreshOperations();
        }
    };

    useEffect(() => { fetchOperationDetails(); }, []);

    const [operation, setOperation] = useState({
        id: 0,
        kind: null,
        description: "",
        date: null,
        value: 0,
        persons: [],
        payment: { name: "", codename: "" },
        company: null,
        category: { name: "", codename: "" },
        additionalCategories: []
    });

    const categoryFormatter = (category) => {
        const icon = getCategoryIcon(category.codename);
        return <span className={"text-" + icon.color}><i className={"fa fa-" + icon.icon}></i> {category.name}</span>
    };

    return (
        <div className="row mb-2 pb-3 border-bottom">
            <div className="col-7">
                <div className="mt-2 mb-4 py-2">
                    {operation.company ?
                        <div className="fw-bold text-uppercase mb-2">
                            {operation.company}
                        </div> :
                        <></>
                    }
                    <div>
                        {operation.description}
                    </div>
                </div>
                <div className="col-4">
                    <div className="border-bottom my-2 pb-2 text-uppercase">
                        <small>Typ operacji</small>
                    </div>
                    <div className="text-uppercase mb-4">
                        {operation.kind == "Expense" ? <span className="text-danger">Wydatek - {operation.payment.name}</span> : <span className="text-success">Środki - {operation.payment.name}</span>}
                    </div>
                </div>
            </div>
            <div className="col-2">
                {operation.persons && operation.persons.length > 0 ?
                    <>
                        <div className="border-bottom my-2 pb-2 text-uppercase">
                            <small>Osoby</small>
                        </div>
                        <div>
                            <ul className="list-group">
                                {operation.persons.map(person =>
                                    <li key={person} className="list-unstyled text-primary"><small className={person.slice(-1) == "a" ? "fa fa-female me-1" : "fa fa-male me-1"} ></small> {person}</li>
                                )}
                            </ul>
                        </div>
                    </> :
                    <></>
                }
            </div>
            <div className="col-3">
                <div className="border-bottom my-2 pb-2 text-uppercase">
                    <small>Kategoria</small>
                </div>
                <div>
                    {categoryFormatter(operation.category)}
                </div>
                {operation.additionalCategories && operation.additionalCategories.length > 0 ?
                    <>
                        <div className="border-bottom my-2 pb-2 text-uppercase">
                            <small>Kategorie dodatkowe</small>
                        </div>
                        <div>
                            <ul className="list-group">
                                {operation.additionalCategories.map(category =>
                                    <li key={category.codename} className="list-unstyled">{categoryFormatter(category)}</li>
                                )}
                            </ul>
                        </div>
                    </> :
                    <></>
                }
            </div>
            <div className="col-12">
                <button className="btn btn-sm btn-outline-primary me-3" onClick={() => props.showOperation(props.id)}>Edytuj</button>
                <button className="btn btn-sm btn-outline-warning" onClick={() => { removeOperation(props.id); }}>Usuń</button>
            </div>
        </div>
    );
}

export default OperationDetails;