import React, { useState } from "react";
import { remove, update, getTypes } from "../../../../redux/monthDefinitionRedux";

import { useDispatch, useSelector } from 'react-redux';

const Item = (props) => {

    const dispatch = useDispatch();
    const types = useSelector(state => getTypes(state));

    const [editType, setEditType] = useState(false);
    const [editValue, setEditValue] = useState(false);
    const [value, setValue] = useState(props.item.value);

    return (
        <>
            <div className="col-6">
                {editType ?
                    <>
                        <div className="input-group input-group-sm me-1" >
                            <select className="form-control"
                                value={props.item.monthDefinitionTypeId}
                                autoFocus
                                onBlur={() => setEditType(!editType)}
                                onChange={(e) => { dispatch(update({ ...props.item, monthDefinitionTypeId: parseInt(e.target.value) })) }}>
                                <option disabled value={0}>Wybierz typ</option>
                                {types.map((item) => (
                                    <option value={item.id} key={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </> :
                    <>
                        <div className="p-1" onClick={() => setEditType(!editType)}>
                            {props.item.monthDefinitionTypeId > 0 ? types.filter(a => a.id === props.item.monthDefinitionTypeId)[0].name : "Wybierz typ"}
                        </div>
                    </>
                }
            </div>
            <div className="col-5 text-success d-flex justify-content-end">
                {editValue ?
                    <>
                        <div className="input-group input-group-sm w-50 me-1">
                            <input className="form-control" style={{ textAlign: "right" }}
                                value={value}
                                autoFocus
                                onBlur={(e) => { setEditValue(!editValue); dispatch(update({ ...props.item, value: parseFloat(e.target.value.replace(",", ".")) })) }}
                                onChange={(e) => setValue(e.target.value)}
                            />
                        </div>
                    </> :
                    <>
                        <div className="p-1" onClick={() => setEditValue(!editValue)}>
                            {props.item.value.toLocaleString("fr-FR")}
                        </div>
                    </>
                }
                <div className="p-1">
                    <span>PLN</span>
                </div>
            </div>
            <div className="col-1">
                <button className="btn btn-sm btn-danger" onClick={() => dispatch(remove(props.item.id))}><em className="fa fa-close"></em></button>
            </div>
        </>
    );
}

export default Item