import React, { useState } from "react";
import { useIdentity } from "../../identity/IdentityProvider";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import BaseSinglePage from "../BaseSinglePage";
import { Alert } from "reactstrap";

const SetPassword = () => {
  const [params] = useSearchParams();
  const { setPassword } = useIdentity();
  const [data, setData] = useState({ token: params.get("token"), password: "", confirmPassword: "" });
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setPassword(data).then(res => {
      if (res === true) {
        setSuccess(true);
      }
      else {
        setErrors(res.errors);
        setSuccess(false);
      }
    });
  }

  const setPass = (password) => { setData({ ...data, password: password }); }
  const setConfirmPass = (confirmPassword) => { setData({ ...data, confirmPassword: confirmPassword }); }

  return (
    (!params.get("token") ?
      <Navigate to={"/404"} />
      :
      <BaseSinglePage>
        <div className="p-lg-5 p-4">
          <div>
            <h5>Ustawianie nowego hasła</h5>
            <p className="text-muted">Przywracanie dostępu do systemu.</p>
          </div>
          <div className="mt-4 pt-3">
            {(success ?
              <div className="alert alert-success text-center mb-4" role="alert">
                Ustawiono nowe hasło.
              </div>
              :
              <>
                {errors.map(error => (<Alert key={error} color="danger">{error}</Alert>))}
                <form className="form-horizontal" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="password" className="mb-2">Nowe hasło</label>
                    <input name="password" className="form-control" placeholder="Wprowadź hasło" type="password" required value={data.password} onInput={e => setPass(e.target.value)} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="confirmPassword" className="mb-2">Powtórzone hasło</label>
                    <input name="confirmPassword" className="form-control" placeholder="Wprowadź powtórzone hasło" type="password" required value={data.confirmPassword} onInput={e => setConfirmPass(e.target.value)} />
                  </div>
                  <div className="mt-4 text-end">
                    <button className="btn btn-primary w-100" type="submit">Zapisz</button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
        <div className="text-center">
          <p>Powrót do <Link to="/login" className="text-decoration-underline">Logowania</Link> </p>
        </div>
      </BaseSinglePage>
    )
  )
}

export default SetPassword