import { Label } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getFilters, updateFilters } from "../../../redux/budgetRedux.js";

const YearsFilter = (props) => {
    const start = props.from ? props.from : 2015;
    const years = [];
    for (let year = start; year <= new Date().getFullYear() + 1; year++) {
        years[year - start] = year;
    }

    const dispatch = useDispatch();

    const filters = useSelector(state => getFilters(state));

    return (
        <div className="form-floating mb-3">
            <select className="form-select" value={filters.year} onChange={e => {
                e.preventDefault();
                dispatch(updateFilters({  ...filters, year: parseInt(e.target.value) }))
            }}>
                {years.map(year => (
                    <option key={year} value={year}>{year}</option>
                ))}
            </select>
            <Label>Wybrany rok</Label>
        </div>
    );
}

export default YearsFilter;