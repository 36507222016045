import "./Header.module.scss";
import Container from "react-bootstrap/Container";
import logoDark from "../../../assets/images/logo-dark.png";
import NotificationMenu from "../../common/NotificationMenu/NotificationMenu";
import ProfileMenu from "../../common/ProfileMenu/ProfileMenu";
import { Link } from "react-router-dom";
import SearchMenu from "../../common/SearchMenu/SearchMenu";

const Header = () => {
    return (
        <header>
            <Container className="py-2">
                <div className="d-flex justify-content-between">
                    <div>
                        <Link to="/" className="navbar-brand pt-1 me-5">
                            <img src={logoDark} alt="" height="24" className="d-inline-block align-text-top" />
                        </Link>
                    </div>
                    <div>
                        <SearchMenu />
                        <NotificationMenu />
                        <ProfileMenu />
                    </div>
                </div>
            </Container>
        </header>
    );
}

export default Header;