import { Label } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getYear, setYear } from "../../../../redux/monthDefinitionRedux";

const Years = () => {
    const start = new Date().getFullYear() - 1;
    const years = [];
    for (let year = start; year <= new Date().getFullYear() + 1; year++) {
        years[year - start] = year;
    }

    const dispatch = useDispatch();

    const year = useSelector(state => getYear(state));

    return (
        <div className="form-floating mb-3">
            <select className="form-select" value={year} onChange={e => {
                e.preventDefault();
                dispatch(setYear(parseInt(e.target.value)))
            }}>
                {years.map(year => (
                    <option key={year} value={year}>{year}</option>
                ))}
            </select>
            <Label>Wybrany rok</Label>
        </div>
    );
}

export default Years;