import { Label } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getMonth, setMonth } from "../../../../redux/monthDefinitionRedux";

const Months = () => {
    const months = [
        { id: 1, name: "Styczeń" },
        { id: 2, name: "Luty" },
        { id: 3, name: "Marzec" },
        { id: 4, name: "Kwiecień" },
        { id: 5, name: "Maj" },
        { id: 6, name: "Czerwiec" },
        { id: 7, name: "Lipiec" },
        { id: 8, name: "Sierpień" },
        { id: 9, name: "Wrzesień" },
        { id: 10, name: "Październik" },
        { id: 11, name: "Listopad" },
        { id: 12, name: "Grudzień" },
    ];

    const dispatch = useDispatch();

    const month = useSelector(state => getMonth(state));

    return (
        <div className="form-floating mb-3">
            <select className="form-select" value={month} onChange={e => {
                e.preventDefault();
                dispatch(setMonth(parseInt(e.target.value)))
            }}>
                {months.map(month => (
                    <option key={month.id} value={month.id}>{month.name}</option>
                ))}
            </select>
            <Label>Wybrany miesiąc</Label>
        </div>
    );
}

export default Months;