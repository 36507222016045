import { useDispatch, useSelector } from 'react-redux';
import { getFilters, updateFilters } from "../../../redux/budgetRedux.js";

const AmountsFilter = () => {

    const dispatch = useDispatch();

    const filters = useSelector(state => getFilters(state));

    return (
        <div className="d-flex mb-3">
            <input type="number" className="form-control" placeholder="0,00" value={filters.from} onInput={e => {
                e.preventDefault();
                dispatch(updateFilters({ ...filters, from: e.target.value ? parseFloat(e.target.value) : null }))
            }} />
            <input type="number" className="form-control" placeholder="0,00" value={filters.to} onInput={e => {
                e.preventDefault();
                dispatch(updateFilters({ ...filters, to: e.target.value ? parseFloat(e.target.value) : null }))
            }} />
        </div>
    );
}

export default AmountsFilter;