import Select from "react-select";
import { Label } from "reactstrap";
import endpoints from "../../../../config/endpoints.js";
import { get } from "../../../../api/authApiClient.js";
import { useEffect, useState } from "react";

const CompanyInput = (props) => {

    const fetchCompanies = async () => {
        const data = await get(endpoints.operations.companies);
        if (data.isOk()) {
            setCompanies(data.response.map(company => { return { value: company.id, label: company.name } }));
        }
    };

    useEffect(() => { fetchCompanies(); }, []);
    const [companies, setCompanies] = useState([]);

    return (
        <>
            <Label>Kontrahent</Label>
            <Select
                value={companies.filter(company => company.value == props.value)[0]}
                onChange={(value) => props.setValue(value.value)}
                isMulti={false}
                options={companies}
                classNamePrefix="select2-selection"
                placeholder="Wybierz..."
            />
        </>
    );
}

export default CompanyInput;