import { useDispatch, useSelector } from 'react-redux';
import { getFilters, updateFilters } from "../../../redux/budgetRedux.js";
import Select, { components } from "react-select";
import endpoints from "../../../config/endpoints.js";
import { get } from "../../../api/authApiClient.js";
import { useEffect, useState } from "react";
import getCategoryIcon from './CategoryHelper.js';

const CategoriesFilter = () => {

    const fetchCategories = async () => {
        const url = endpoints.operations.allCategories;

        const data = await get(url);
        if (data.isOk()) {
            setCategories(
                [
                    {
                        label: "Wydatki",
                        options: [
                            ...data.response.filter(cat => cat.kind === "Expense").map(item => { return { label: item.name, value: item.id, icon: getCategoryIcon(item.codename).icon, color: getCategoryIcon(item.codename).color } })
                        ],
                    },
                    {
                        label: "Wpływy",
                        options: [
                            ...data.response.filter(cat => cat.kind === "Profit").map(item => { return { label: item.name, value: item.id, icon: getCategoryIcon(item.codename).icon, color: getCategoryIcon(item.codename).color } })
                        ],
                    }
                ]
            );
        }
    };

    useEffect(() => { fetchCategories(); }, []);

    const [categories, setCategories] = useState([]);

    const dispatch = useDispatch();

    const filters = useSelector(state => getFilters(state));

    const handleChange = value => {
        const ids = value.map(a => a.value);
        dispatch(updateFilters({ ...filters, categoryIds: ids }));
    }

    const { Option } = components;
    const IconOption = props => (
        <Option {...props}>
            <span className={"text-" + props.data.color}><i className={"fa fa-" + props.data.icon}></i> {props.data.label}</span>
        </Option>
    );

    return (
        <Select isMulti={true} options={categories} components={{ Option: IconOption }} classNamePrefix="select2-selection" className="mb-3" placeholder="" onChange={handleChange} />
    );
}

export default CategoriesFilter;