const getPaymentIconHtml = (codename) => {
    switch (codename) {
        case "credit_card":
            return (<i className="fa fa-credit-card me-1"></i>)
        case "money":
            return (<i className="fa fa-money me-1"></i>)
        case "blik":
            return (<i className="fa fa-cc-paypal me-1"></i>)
        default:
        case "exchange":
            return (<i className="fa fa-exchange me-1"></i>)
    }
}

export default getPaymentIconHtml;