import { Label } from "reactstrap";

const DescriptionInput = (props) => {
    return (
        <>
            <Label>Opis</Label>
            <div>
                <input className="form-control" type="text" value={props.value} onChange={(e) => props.setValue(e.target.value)} />
            </div>
        </>
    );
}

export default DescriptionInput;