import Select, { components } from "react-select";
import { Label } from "reactstrap";
import endpoints from "../../../../config/endpoints.js";
import { get } from "../../../../api/authApiClient.js";
import { useEffect, useState } from "react";
import getCategoryIcon from "../CategoryHelper.js";

const CategoryInput = (props) => {

    const fetchCategories = async () => {
        const data = await get(props.type === "Expense" ? endpoints.operations.expenseCategories : endpoints.operations.profitCategories);
        if (data.isOk()) {
            setCategories(data.response.map(category => { return { value: category.id, label: category.name, codename: category.codename } }));
        }
    };
    
    useEffect(() => { fetchCategories(); }, [props.type]);
    const [categories, setCategories] = useState([]);

    const categoryHtml = (name, codename) => {
        const category = getCategoryIcon(codename);
        return (
            <>
                {/* <span className={"text-" + category.color}><i className={"fa fa-" + category.icon}></i> {name}</span> */}
                <span><i className={"fa fa-" + category.icon}></i> {name}</span>
            </>);
    }

    const CategoryOption = props => (
        <components.Option {...props}>
            {categoryHtml(props.data.label, props.data.codename)}
        </components.Option>
    );

    const SingleCategorySelected = props => {
        return (
            <components.SingleValue {...props}>
                {categoryHtml(props.data.label, props.data.codename)}
            </components.SingleValue>
        );
    };

    const MultiCategorySelected = props => {
        return (
            <components.MultiValue {...props}>
                {categoryHtml(props.data.label, props.data.codename)}
            </components.MultiValue>
        );
    };

    // const MultiCategorySelected = props => {
    //     const category = getCategoryIcon(props.data.codename);
    //     return (
    //         <div className={"border border-" + category.color + " rounded px-1 my-1"}>
    //             {categoryHtml(props.data.label, props.data.codename)}
    //             <span className={"fa fa-times text-" + category.color + " mx-1"} onClick={() => { props.removeProps.onClick() }}></span>
    //         </div>
    //     );
    // };

    const handleChange = value => {
        props.setValue(value.value);
    }

    const multiHandleChange = value => {
        const ids = value.map(a => a.value);
        props.setValue(ids);
    }

    return (
        <>
            <Label className="form-label">{props.label}</Label>
            <Select
                value={props.multi ? categories.filter(category => props.value.includes(category.value)) : categories.filter(category => category.value == props.value)[0]}
                isMulti={props.multi}
                components={{ Option: CategoryOption, SingleValue: SingleCategorySelected, MultiValue: MultiCategorySelected }}
                options={categories}
                placeholder="Wybierz..."
                classNamePrefix="select2-selection"
                closeMenuOnSelect={true}
                onChange={props.multi ? multiHandleChange : handleChange}
            />
        </>
    );
}

export default CategoryInput;