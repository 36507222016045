import { useLayoutEffect, useState } from "react";
import { get, put } from "../../../../api/authApiClient.js";
import endpoints from "../../../../config/endpoints.js";
import ModalForm from "../../../common/ModalForm/ModalForm";

import Years from "./Years";
import Months from "./Months";
import Item from "./Item";

import { useDispatch, useSelector } from 'react-redux';
import { hideMonthDefinition, setItems, getYear, getMonth, getItems, setTypes, getTypes, add } from "../../../../redux/monthDefinitionRedux";

const MonthDefinitionForm = () => {

    const dispatch = useDispatch();
    const year = useSelector(state => getYear(state));
    const month = useSelector(state => getMonth(state));
    const items = useSelector(state => getItems(state));

    const [errors, setErrors] = useState([]);

    const fetchTypes = async () => {
        const url = endpoints.monthDefinitions.types;
        const data = await get(url);
        if (data.isOk()) {
            dispatch(setTypes(data.response));
        }
    };

    const fetchData = async () => {
        const url = endpoints.monthDefinitions.all(year, month);
        const data = await get(url);
        if (data.isOk()) {
            dispatch(setItems(data.response));
        }
    };

    const updateOperation = async () => {
        const url = endpoints.monthDefinitions.update(year, month);
        const result = await put(url, items);
        if (result.isError()) {
            setErrors(result.response.errors);
            return false;
        }
        else
            return true;
    };

    const types = useSelector(state => getTypes(state));

    useLayoutEffect(() => {
        if (types.length > 0)
            fetchData();
        else
            fetchTypes().then(() => fetchData());
    }, [year, month]);

    const save = async () => {
        updateOperation().then((res) => { if (res) { dispatch(hideMonthDefinition()); } });
    }

    return (
        <ModalForm title={"Definiowanie miesiąca"} isOpen={true} toggle={() => dispatch(hideMonthDefinition())} onAccept={save} >
            <div className="row mb-3">
                <div className="col-6">
                    <Years />
                </div>
                <div className="col-6">
                    <Months />
                </div>
            </div>

            <div className="row">
                <span className="text-danger"><ul className="list-unstyled">{errors.map(a => <li key={a.message}>{a.message}</li>)}</ul></span>
            </div>

            <div className="mx-3">
                <div className="row border-bottom border-2 border-primary text-primary text-uppercase py-2">
                    <div className="col-6">Typ</div>
                    <div className="col-5 text-end">Kwota</div>
                    <div className="col-1"></div>
                </div>

                {items && items.length > 0 ?
                    items.map((item) => (
                        <div className="row border-bottom border-1 border-primary py-2" key={item.id}>
                            <Item item={item} />
                        </div>
                    )) :
                    <div className="row"><div className="col-12 text-center mt-3" colSpan="7">Brak wyników</div></div>}
                <div className="row mt-2">
                    <div className="col-1 offset-11">
                        <button className="btn btn-sm btn-success" onClick={() => dispatch(add())}><em className="fa fa-plus"></em></button>
                    </div>
                </div>
            </div>
        </ModalForm>
    );
}

export default MonthDefinitionForm;