import ModalForm from "../../../common/ModalForm/ModalForm";
import PaymentInput from "./PaymentInput.js";
import AmountInput from "./AmountInput";
import CategoryInput from "./CategoryInput";
import PersonInput from "./PersonInput";
import CompanyInput from "./CompanyInput";
import DateInput from "./DateInput";
import DescriptionInput from "./DescriptionInput";
import { get, post, put } from "../../../../api/authApiClient.js";
import endpoints from "../../../../config/endpoints.js";
import { useLayoutEffect, useState } from "react";

const OperationForm = (props) => {

    const [id, setId] = useState(props.id ? props.id : 0);

    const [operation, setOperation] = useState({
        kind: props.type,
        description: "",
        date: new Date(),
        value: undefined,
        paymentId: 0,
        companyId: null,
        personIds: [],
        categoryId: 0,
        additionalCategoryIds: []
    });

    const [errors, setErrors] = useState([]);

    const title = () => {
        switch (props.type) {
            default:
            case "Expense":
                return "Wydatek";
            case "Profit":
                return "Środki";
        }
    }

    const fetchOperation = async () => {
        if (id > 0) {
            const url = endpoints.operations.single(id);
            const data = await get(url);
            if (data.isOk()) {
                setOperation({ ...data.response, date: new Date(data.response.date), value: data.response.value.toString() });
            }
        }
    };

    const createOperation = async () => {
        const data = { ...operation, value: prepareValue(operation.value), date: operation.date.toISOString() };
        const result = await post(endpoints.operations.create, data);
        if (result.isError()) {
            setErrors(result.response.errors);
            return false;
        }
        else
            return true;
    };

    const updateOperation = async () => {
        const url = endpoints.operations.update(id);
        const data = { ...operation, value: prepareValue(operation.value), date: operation.date.toISOString() };
        const result = await put(url, data);
        if (result.isError()) {
            setErrors(result.response.errors);
            return false;
        } else
            return true;
    };

    const prepareValue = (val) => {
        const value = val ? val.replace(",", ".") : "";
        return parseFloat(value) ? parseFloat(value) : 0;
    };

    useLayoutEffect(() => { fetchOperation(); }, []);

    const save = async () => {
        if (id > 0)
            updateOperation().then((res) => { if (res) { props.refreshOperation(); props.hide(); } });
        else
            createOperation().then((res) => { if (res) { props.refreshOperation(); props.hide(); } });
    }

    return (
        <ModalForm title={title()} isOpen={true} toggle={props.hide} onAccept={save} >
            <div className="row mb-3">
                <div className="col-md-6">
                    <CategoryInput label={"Kategoria"} multi={false} type={props.type} value={operation.categoryId} setValue={(id) => setOperation({ ...operation, categoryId: id })} />
                    <span className="text-danger"><ul className="list-unstyled">{errors.filter(a => a.key === "categoryId").map(a => <li key={a.message}>{a.message}</li>)}</ul></span>
                </div>
                <div className="col-md-6">
                    <DateInput value={operation.date} setValue={(date) => setOperation({ ...operation, date: date })} />
                    <span className="text-danger"><ul className="list-unstyled">{errors.filter(a => a.key === "date").map(a => <li key={a.message}>{a.message}</li>)}</ul></span>
                </div>
            </div>
            <div className="row mb-3">
                <DescriptionInput value={operation.description} setValue={(desc) => setOperation({ ...operation, description: desc })} />
                <span className="text-danger"><ul className="list-unstyled">{errors.filter(a => a.key === "description").map(a => <li key={a.message}>{a.message}</li>)}</ul></span>
            </div>
            <div className="row mb-3">
                <CompanyInput value={operation.companyId} setValue={(id) => setOperation({ ...operation, companyId: id })} />
                <span className="text-danger"><ul className="list-unstyled">{errors.filter(a => a.key === "companyId").map(a => <li key={a.message}>{a.message}</li>)}</ul></span>
            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    <AmountInput value={operation.value} setValue={(value) => setOperation({ ...operation, value: value })} />
                    <span className="text-danger"><ul className="list-unstyled">{errors.filter(a => a.key === "value").map(a => <li key={a.message}>{a.message}</li>)}</ul></span>
                </div>
                <div className="col-md-6">
                    <PaymentInput value={operation.paymentId} setValue={(id) => setOperation({ ...operation, paymentId: id })} />
                    <span className="text-danger"><ul className="list-unstyled">{errors.filter(a => a.key === "paymentId").map(a => <li key={a.message}>{a.message}</li>)}</ul></span>
                </div>
            </div>
            <div className="row mb-3">
                <PersonInput value={operation.personIds} setValue={(ids) => setOperation({ ...operation, personIds: ids })} />
                <span className="text-danger"><ul className="list-unstyled">{errors.filter(a => a.key === "personIds").map(a => <li key={a.message}>{a.message}</li>)}</ul></span>
            </div>
            <div className="row mb-3">
                <CategoryInput label={"Dodatkowe kategorie"} multi={true} type={props.type} value={operation.additionalCategoryIds} setValue={(ids) => setOperation({ ...operation, additionalCategoryIds: ids })} />
                <span className="text-danger"><ul className="list-unstyled">{errors.filter(a => a.key === "additionalCategoryIds").map(a => <li key={a.message}>{a.message}</li>)}</ul></span>
            </div>
        </ModalForm>
    );
}

export default OperationForm;