import { Label } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getFilters, updateFilters } from "../../../redux/budgetRedux.js";

const MonthsFilter = () => {
    const months = [
        { id: 1, name: "Styczeń" },
        { id: 2, name: "Luty" },
        { id: 3, name: "Marzec" },
        { id: 4, name: "Kwiecień" },
        { id: 5, name: "Maj" },
        { id: 6, name: "Czerwiec" },
        { id: 7, name: "Lipiec" },
        { id: 8, name: "Sierpień" },
        { id: 9, name: "Wrzesień" },
        { id: 10, name: "Październik" },
        { id: 11, name: "Listopad" },
        { id: 12, name: "Grudzień" },
        { id: 13, name: "I kwartał" },
        { id: 14, name: "II kwartał" },
        { id: 15, name: "III kwartał" },
        { id: 16, name: "IV kwartał" },
        { id: 17, name: "Cały rok" }
    ];

    const dispatch = useDispatch();

    const filters = useSelector(state => getFilters(state));

    return (
        <div className="form-floating mb-3">
            <select className="form-select" value={filters.month} onChange={e => {
                e.preventDefault();
                dispatch(updateFilters({ ...filters, month: parseInt(e.target.value) }))
            }}>
                {months.map(month => (
                    <option key={month.id} value={month.id}>{month.name}</option>
                ))}
            </select>
            <Label>Wybrany miesiąc</Label>
        </div>
    );
}

export default MonthsFilter;