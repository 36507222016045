import "./Navbar.module.scss";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";

const NavBar = () => {
    return (
        <Navbar expand="lg" className="">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        <Nav.Link as={NavLink} to="/" className="me-4 text-light"><i className="fa fa-first-order me-2"></i>Strona główna</Nav.Link>
                        <Nav.Link as={NavLink} to="/budget" className="me-4 text-light"><i className="fa fa-university me-2"></i>Budżet</Nav.Link>
                        <Nav.Link as={NavLink} to="/documents" className="me-4 text-light"><i className="fa fa-file-text-o me-2"></i>Dokumenty</Nav.Link>
                        <Nav.Link as={NavLink} to="/files" className="me-4 text-light"><i className="fa fa-folder-open-o me-2"></i>Pliki</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;