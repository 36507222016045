import React, { useState } from "react"
import "./ProfileMenu.module.scss";
import "../../../styles/icons.scss";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useIdentity } from "../../identity/IdentityProvider.js";

const ProfileMenu = () => {
    const [menu, setMenu] = useState(false);
    const { logout } = useIdentity();

    return (
        <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
            <DropdownToggle className="btn header-icon-none-focus" tag="button" >
                <i className="fa fa-user header-icon-menu d-none d-xl-inline-block text-muted" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
                <Link to="/settings" className="dropdown-item">
                    <i className="fa fa-wrench me-1 text-muted" />
                    <span>Ustawienia</span>
                </Link>
                <div className="dropdown-divider" />
                <DropdownItem onClick={logout}>
                    <i className="fa fa-sign-out me-1 text-danger" />
                    <span>Wyloguj</span>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default ProfileMenu;