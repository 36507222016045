import "./Breadcrumb.scss";
import React from "react"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  return (
    <Row className="my-3">
      <Col xs="12">
        <div className="breadcrumb-box d-flex align-items-center justify-content-between">
          <h4 className="m-0"
          //style={{fontSize:"16px", fontWeight:"700", lineHeight:"19.2px", color:"rgb(73,80,87)", outlineColor:"rgb(73,80,87)", outlineStyle:"none", outlineWidth:"0px"}}
          >{props.breadcrumbItem}</h4>
          <div>
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="#">{props.title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to="#">{props.breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Breadcrumb