import React, { useState } from "react";
import { useIdentity } from "../../identity/IdentityProvider.js";
import { Link } from "react-router-dom";
import BaseSinglePage from "../BaseSinglePage";

const ForgetPassword = () => {
  const { forgetPassword } = useIdentity();
  const [data, setData] = useState({ email: "" });
  const [success, setSuccess] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    forgetPassword(data).then(res => {
      setSuccess(res);
    });
  }

  const setEmail = (email) => { setData({ ...data, email: email }); }

  return (
    <BaseSinglePage>
      <div className="p-lg-5 p-4">
        <div>
          <h5>Resetowanie hasła</h5>
          <p className="text-muted">Przywracanie dostępu do systemu.</p>
        </div>
        <div className="mt-4 pt-3">
          {(success ?
            <div className="alert alert-success text-center mb-4" role="alert">
              Sprawdź pocztę, aby dokończyć resetowanie hasła.
            </div>
            :
            <>
              <div className="alert alert-success text-center mb-4" role="alert">
                Wprowadź adres email w celu odzyskania hasła!
              </div>
              <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="email" className="mb-2">Email</label>
                  <input name="email" className="form-control" placeholder="Wprowadź email" type="email" required value={data.email} onInput={e => setEmail(e.target.value)} />
                </div>
                <div className="mt-4 text-end">
                  <button className="btn btn-primary w-100" type="submit">Resetuj</button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
      <div className="text-center">
        <p>Powrót do <Link to="/login" className="text-decoration-underline">Logowania</Link> </p>
      </div>
    </BaseSinglePage>
  )
}

export default ForgetPassword