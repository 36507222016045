const getCategoryIcon = (codename) => {
    switch (codename) {
        default:
        case "other":
            return { icon: "info", color: "warning" };
        case "paycheck":
            return { icon: "money", color: "primary" };
        case "return":
            return { icon: "retweet", color: "primary" };
        case "shopping":
            return { icon: "shopping-cart", color: "primary" };
        case "food":
            return { icon: "cutlery", color: "success" };
        case "fuel":
            return { icon: "battery", color: "danger" };
        case "mortgage":
            return { icon: "home", color: "warning" };
        case "kindergarten":
            return { icon: "child", color: "primary" };
        case "rent":
            return { icon: "home", color: "danger" };
        case "electricity":
            return { icon: "plug", color: "primary" };
        case "gas":
            return { icon: "fire", color: "info" };
        case "telephone":
            return { icon: "mobile", color: "success" };
        case "internet":
            return { icon: "globe", color: "info" };
        case "tickets":
            return { icon: "ticket", color: "success" };
        case "cigarettes":
            return { icon: "leaf", color: "danger" };
        case "other_fees":
            return { icon: "cc-visa", color: "primary" };
        case "clothes":
            return { icon: "user-secret", color: "danger" };
        case "gift":
            return { icon: "gift", color: "success" };
        case "insurance":
            return { icon: "shield", color: "warning" };
        case "savings":
            return { icon: "university", color: "primary" };
        case "treatment":
            return { icon: "ambulance", color: "danger" };
        case "holidays":
            return { icon: "plane", color: "info" };
        case "car":
            return { icon: "car", color: "info" };
        case "penalties":
            return { icon: "gavel", color: "danger" };
        case "parties":
            return { icon: "glass", color: "success" };
        case "school":
            return { icon: "graduation-cap ", color: "success" };
        case "renovation":
            return { icon: "paint-brush", color: "warning" };
        case "tools":
            return { icon: "wrench", color: "danger" };
        case "furniture":
            return { icon: "bed", color: "success" };
        case "buying_home":
            return { icon: "home", color: "primary" };
        case "trash":
            return { icon: "trash", color: "danger" };
        case "water":
            return { icon: "tint", color: "primary" };
        case "rides":
            return { icon: "cab", color: "warning" };
        case "benefit":
            return { icon: "money", color: "success" };
        case "tax":
            return { icon: "gavel", color: "danger" };
    }
}

export default getCategoryIcon;