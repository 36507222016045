// selectors
export const getFilters = ({ budget }) => budget.filters;

// actions
const createActionName = actionName => `app/budget/${actionName}`;
const UPDATE_FILTERS = createActionName("UPDATE_FILTERS");

// action creators
export const updateFilters = payload => ({ type: UPDATE_FILTERS, payload });

const budgetReducer = (statePart = {}, action) => {
  switch (action.type) {
    case UPDATE_FILTERS:
      return { ...statePart, filters: action.payload };
    default:
      return statePart;
  };
};

export default budgetReducer;