import "./Style.scss"
import { Container } from "reactstrap";
import Breadcrumbs from "../../common/Breadcrumb/Breadcrumb.js";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';

import { Card, CardBody, Col, Row } from "reactstrap";
import AmountWidget from "./AmountWidget";
import FilterWidget from "./FilterWidget";
import { getFilters } from "../../../redux/budgetRedux.js";
import { getIsShow } from "../../../redux/monthDefinitionRedux";

import endpoints from "../../../config/endpoints.js";
import { get } from "../../../api/authApiClient.js";
import Operation from "./Operation";
import OperationForm from "./OperationForm/OperationForm.js";
import MonthDefinitionForm from "./MonthDefinition/MonthDefinitionForm.js";

const Budget = () => {

    const fetchOperations = async (params) => {
        const urlParam = new URLSearchParams({ year: params.year, month: params.month, kind: params.kind });
        if (params.search !== null && params.search !== undefined)
            urlParam.set("search", params.search);
        if (params.from !== null && params.from !== undefined)
            urlParam.set("from", params.from);
        if (params.to !== null && params.to !== undefined)
            urlParam.set("to", params.to);
        if (params.categoryIds !== null && params.categoryIds !== undefined) {
            params.categoryIds.forEach(id => {
                urlParam.append("categoryIds", id);
            });
        }

        const url = endpoints.operations.all + "?" + urlParam;

        const data = await get(url);
        if (data.isOk()) {
            setSaldo({ accountBalance: data.response.accountBalance, profitBalance: data.response.profitBalance, expenseBalance: data.response.expenseBalance })
            setOperations(data.response.items);
        }
    };

    const filters = useSelector(state => getFilters(state));

    //useEffect(() => { fetchOperations(filters); }, [filters]);
    useEffect(() => { fetchOperations(filters); }, []);

    const [operations, setOperations] = useState([]);

    const [saldo, setSaldo] = useState({ accountBalance: 0, profitBalance: 0, expenseBalance: 0 });

    const [operationForm, setOperationForm] = useState({ show: false, type: null, id: null });

    const showMonthDefinition = useSelector(state => getIsShow(state));

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Budżet"} breadcrumbItem={"Budżet"} />
                    <Row>
                        <Col className="col-12 col-lg-3">
                            <FilterWidget onFilter={fetchOperations} showOperation={(type, id) => setOperationForm({ show: true, type: type, id: id })} />
                        </Col>
                        <Col className="col-12 col-lg-9">
                            <Row>
                                <Col className="col-12">
                                    <Card>
                                        <CardBody>
                                            <AmountWidget saldo={saldo.accountBalance} profit={saldo.profitBalance} expense={saldo.expenseBalance} />

                                            <div className="">
                                                <div className="row border-bottom border-2 border-primary text-primary text-uppercase py-2">
                                                    <div className="col-1">Typ</div>
                                                    <div className="col-5">Nazwa</div>
                                                    <div className="col-3">Kategoria</div>
                                                    <div className="col-2 text-end">Kwota</div>
                                                    <div className="col-1"></div>
                                                </div>

                                                {operations && operations.length > 0 ?
                                                    operations.map((group) => (
                                                        <React.Fragment key={group.date}>
                                                            <div className="row border-bottom py-2">
                                                                <div className="col fw-bold text-muted text-uppercase">{new Date(group.date).toLocaleDateString()}</div>
                                                            </div>
                                                            {group.operations.map((item) => (
                                                                <Operation key={item.id} operation={item}
                                                                    showOperation={(id) => setOperationForm({ show: true, type: item.kind, id: id })}
                                                                    refreshOperations={() => fetchOperations(filters)} />
                                                            ))}
                                                        </React.Fragment>
                                                    )) :
                                                    <div className="row"><div className="col-12 text-center mt-3" colSpan="7">Brak wyników</div></div>}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {operationForm.show ? <OperationForm type={operationForm.type} id={operationForm.id}
                        hide={() => setOperationForm({ show: false, type: null, id: null })}
                        refreshOperation={() => fetchOperations(filters)} /> :
                        <></>}
                    {showMonthDefinition ? <MonthDefinitionForm /> : <></>}
                </Container>
            </div>
        </>
    );
}

export default Budget