import React from "react";
import { Link } from "react-router-dom";
import BaseSinglePage from "./BaseSinglePage.js";

const InternalServerError = () => {
  return (
    <BaseSinglePage>
      <div className="text-center p-5">
        <em className="fa fa-exclamation-triangle fa-5x"></em>
        <h4 className="text-uppercase mt-4">Błąd serwera</h4>
        <p className="text-muted mt-3">Jeśli błąd będzie się powtarzał zgłoś to administratorowi</p>
        <div className="mt-5">
          <Link className="btn btn-primary w-100" to="/">Powót</Link>
        </div>
      </div>
    </BaseSinglePage>
  );
}

export default InternalServerError;