import React from "react";
import { Navigate } from "react-router-dom";

import Home from "../pages/Home/Home.js";
import Budget from "../pages/Budget/Budget.js";

const protectedRoutes = [
    { path: "/", component: Home },
    { path: "/budget", component: Budget },
    { path: "*", component: () => <Navigate to="/" /> }
]

export default protectedRoutes;