import React from "react";

const AmountWidget = props => {
    return (
        <div className="mb-3">
        <ul className="list-inline main-chart mb-1">
          <li className="list-inline-item chart-border-left pe-3 me-1">
            <h3 className="text-primary">
              <span className="text-muted d-inline-block fw-normal font-size-15">SALDO</span>
              <span className="ms-3">{props.saldo.toLocaleString("fr-FR")} PLN</span>
            </h3>
          </li>
          <li className="list-inline-item chart-border-left pe-3 me-1">
            <h3 className="text-success">
              <span className="text-muted d-inline-block fw-normal font-size-15">WPŁYWY</span>
              <span className="ms-3">{props.profit.toLocaleString("fr-FR")} PLN</span>
            </h3>
          </li>
          <li className="list-inline-item chart-border-left pe-3 me-1">
            <h3 className="text-warning">
              <span className="text-muted d-inline-block fw-normal font-size-15">WYDATKI</span>
              <span className="ms-3">{props.expense.toLocaleString("fr-FR")} PLN</span>
            </h3>
          </li>
        </ul>
      </div>
    );
}

export default AmountWidget